body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom scrollbar styles for the navigation */
.navigation-scrollbar {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch; /* For smooth scrolling on iOS */
}

.navigation-scrollbar::-webkit-scrollbar {
  width: 1px;
  height: 4px;
  margin-top: 5px;

}

.navigation-scrollbar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 8px;
  
}

.navigation-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 8px;
}

.navigation-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

